import React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MiniPage from "../components/minipage"

const description =
  "Pomegenix  is a bunch of designers, developers, and engineers that builds experiences that people enjoy."

const text =
  "The Pomegenix environment is fueled by strong team culture and vibrant creativity. We share a constant drive to challenge ourselves to grow, develop new solutions, and generate novel ideas. With these expectations serving as the foundation for everything we do, we believe that taking care of our employees is paramount. Our expert team of developers, designers, project managers, and leaders work together in a friendly, fun, fast-paced atmosphere with benefits and compensation that surpass the industry standard.Have an abundance of creativity, a strong skill set, and a fresh outlook on life? Check out our benefits and open positions below."

const About = () => {
  return (
    <>
      <Layout>
        <Hero text="About" info="Growth is the heart of business" />
        <SEO title="More about us" description={description} />
        <MiniPage title="About us" text={text} />
      </Layout>
    </>
  )
}

export default About
