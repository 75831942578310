import React from "react"

const MiniPage = ({ title, text }) => {

    var style={
        padding: '60px 5px'
      }

  return (
    <>
      <section style={style}>
        <div className="container">
          <div className="row">
            <h2>{title}</h2>
            <p className="lead text-muted">
              {text}
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default MiniPage
